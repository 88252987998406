// about-header start
.header-com-main {
  padding-top: 100px;
  // height: 100vh;
  height: 500px;
  position: relative;
  width: 100%;
  overflow: hidden;
  z-index: -10;

  .header-compo-1 {
    position: absolute;
    width: 493px;
    right: 0%;
    bottom: 0;
    height: 493px;
    background: linear-gradient(
      180deg,
      rgba(0, 208, 255, 0.2) 0%,
      rgba(0, 89, 255, 0.2) 100%
    );
    border-radius: 50%;
    transform: translateX(40%);

    z-index: -1;
  }

  .header-compo-2 {
    position: absolute;
    width: 290px;
    left: 0%;
    top: 160px;
    height: 290px;
    background: linear-gradient(
      180deg,
      rgba(0, 208, 255, 0.2) 0%,
      rgba(0, 89, 255, 0.2) 100%
    );
    border-radius: 50%;
    transform: translateX(-65%);
    z-index: -1;
  }

  .about-header-left {
    // padding-left: 180px;

    > div {
      font-size: 60px;
      font-weight: 700;
      background: -webkit-linear-gradient(left, #38deff, #0053ff);
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    > p {
      font-size: 18px;
      font-weight: normal;
      color: rgba(0, 0, 0, 0.5);
    }
  }
}

@media only screen and (max-width: 998px) {
  .header-main {
    .header-header-left {
      padding-left: 20px;
    }
  }
}
// about-header end
.about_flu {
  background: rgba(243, 247, 253, 0.65);
}
.about_con {
  padding-top: 80px;
}
/* .parent {
        position: relative;
        top: 0;
        left: 0;
        height: 500px;
        width: auto;
        
      }
      .image1 {
            position: relative;
    
        top: 0;
        left: 0;
       
      }
      .image2 {
        position: absolute;
        top: 150px;
        left: 150px;
    
      } */
#wrapper1 {
  max-width: 1050px;
  /* margin: 60px auto 60px auto; */
  position: relative;
}
#outer1 {
  display: table-cell;
  max-width: 100%;
  table-layout: fixed;
  height: 460px;
}
.itemwrapper2 {
  display: table-cell;
  vertical-align: top;
  width: 70%;
  position: absolute;
}
#outer1 .itemwrapper1 {
  position: absolute;
  top: 150px;
  left: 100px;
}
#outer1 img {
  max-width: 100%;
  height: auto;
}

.about_con .txt span {
  font-weight: 700;
  font-size: 50px;
  background: -webkit-linear-gradient(#38deff, #0053ff);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.about_con .txt p {
  font-size: 16px;
}

.about_con .row .vision1 {
  padding-top: 100px;
}
.about_con .row .vision1 div {
  background-color: white;
  padding-top: 90px;
  margin: 10px;
  padding: 15px;
}
.about_con .row .vision1 div img {
  padding: 28px 0 15px 0;
}
.about_con .row .vision1 div h5 {
  padding: 8px 0 0 0;
  font-weight: 700;
}

.about_con .row .vision1 div p {
  font-size: 14px;
}

.about_con .faq {
  padding-top: 50px;
  margin: auto;
}

.about_con .faq h1 {
  background-image: -webkit-linear-gradient(360deg, #00c8ff 30%, #0060ff 60%);
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-weight: 700;
  line-height: 40px;
}

.faq-main-card {
  background-color: #fff;
  border: none;
  border-radius: 12px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.02) !important;

  .faq-card-title {
    display: flex;
    justify-content: space-between;
    background: none;
    border: none;
    font-size: 1em;
    font-weight: 500;

    > span {
      &:nth-child(1) {
        text-align: left;
      }
    }
  }

  .faq-toggle {
    margin: 0 0 0 20px;
    transition: all 0.2s;
    transform: rotate(270deg);
    display: flex;
    align-items: center;
    height: 100%;

    &.active {
      transform: rotate(360deg);
    }
  }

  .faq-ans {
    font-size: 0.8em;
  }
}

.team1 .team_text {
  text-align: center;
  padding-top: 30px;
  padding-bottom: 10px;
}
.team1 .team_text h1 {
  background-image: -webkit-linear-gradient(#00c8ff, #0060ff);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 700;
}

.team1 .team_text p {
  font-size: 15px;
}

.team1 {
  background: #eff5ff;
}

.team1 .team_member {
  padding-top: 10px;
  padding-bottom: 30px;
}
.team1 .team_member p {
  font-size: 12px;
}

.about-left-img {
  position: relative;
  // transform: perspective(400px) rotateY(15deg);

  > .about-img-bg {
    position: absolute;
    bottom: 0;
    left: -10%;
    width: 200px;
    height: 200px;
    border-radius: 100%;
    background: linear-gradient(rgba(#38deff, 0.5), rgba(#0053ff, 0.5));
  }
}

.header-comp-A {
  position: absolute;
  width: 650px;
  height: 650px;
  border-radius: 100%;
  transform: scale(2);
  background: linear-gradient(rgba(#38deff, 0.07), rgba(#0053ff, 0.07));
  animation: about-header-comp-A 2.5s linear infinite;
}

.header-comp-B {
  position: absolute;
  width: 500px;
  height: 500px;
  border-radius: 100%;
  transform: scale(1.4);
  background: linear-gradient(rgba(#38deff, 0.07), rgba(#0053ff, 0.07));
  animation: about-header-comp-B 2.5s linear infinite;
}

.about-header-comp-C {
  position: absolute;
  width: 350px;
  height: 350px;
  border-radius: 100%;
  transform: scale(0.8);
  background: linear-gradient(rgba(#38deff, 0.07), rgba(#0053ff, 0.07));
  animation: about-header-comp-C 2.5s linear infinite;
}

.header-main-section {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

// @keyframes about-header-comp-A {
//   from {
//     opacity: 0;
//     transform: scale(0) rotate(360deg);
//   }
//   to {
//     opacity: 1;
//     transform: scale(1.8) rotate(0deg);
//   }
// }

// @keyframes about-header-comp-B {
//   from {
//     opacity: 1;
//     transform: scale(1.3) rotate(0deg);
//   }
//   to {
//     opacity: 0;
//     transform: scale(0) rotate(360deg);
//   }
// }

// @keyframes about-header-comp-C {
//   from {
//     opacity: 0;
//     transform: scale(0) rotate(360deg);
//   }
//   to {
//     opacity: 1;
//     transform: scale(0.3) rotate(0deg);
//   }
// }

.header-title {
  color: #ffffff;
  z-index: 5;
  text-align: center;
  // text-shadow: 10px 10px 10px rgba(0,0,0,0.05), 20px 20px 20px rgba(0,0,0,0.05), 30px 30px 30px rgba(0,0,0,0.05);
  // mix-blend-mode: multiply;
  background: linear-gradient(
    to right,
    #00c5ff 20%,
    #0053ff 40%,
    #0053ff 60%,
    #00c5ff 80%
  );
  background-size: 200% auto;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  > div:nth-child(1) {
    animation: shine 10s linear infinite;
    font-size: 50px;
    font-weight: bold;
    text-transform: uppercase;
  }

  > div:nth-child(2) {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    text-transform: capitalize;
  }
}

@keyframes shine {
  to {
    background-position: 200% center;
  }
}

.circles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.circles li {
  position: absolute;
  display: block;
  list-style: none;
  width: 20px;
  height: 20px;
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.1);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  animation: animate 25s linear infinite;
  bottom: -150px;
}

.circles li:nth-child(1) {
  left: 25%;
  width: 80px;
  height: 80px;
  animation-delay: 0s;
}

.circles li:nth-child(2) {
  left: 10%;
  width: 20px;
  height: 20px;
  animation-delay: 2s;
  animation-duration: 12s;
}

.circles li:nth-child(3) {
  left: 70%;
  width: 20px;
  height: 20px;
  animation-delay: 4s;
}

.circles li:nth-child(4) {
  left: 40%;
  width: 60px;
  height: 60px;
  animation-delay: 0s;
  animation-duration: 18s;
}

.circles li:nth-child(5) {
  left: 65%;
  width: 20px;
  height: 20px;
  animation-delay: 0s;
}

.circles li:nth-child(6) {
  left: 75%;
  width: 110px;
  height: 110px;
  animation-delay: 3s;
}

.circles li:nth-child(7) {
  left: 35%;
  width: 150px;
  height: 150px;
  animation-delay: 7s;
}

.circles li:nth-child(8) {
  left: 50%;
  width: 25px;
  height: 25px;
  animation-delay: 15s;
  animation-duration: 45s;
}

.circles li:nth-child(9) {
  left: 20%;
  width: 15px;
  height: 15px;
  animation-delay: 2s;
  animation-duration: 35s;
}

.circles li:nth-child(10) {
  left: 85%;
  width: 150px;
  height: 150px;
  animation-delay: 0s;
  animation-duration: 11s;
}

@keyframes animate {
  0% {
    transform: translateY(0) rotate(0deg);
    opacity: 1;
    // border-radius: 0;
  }

  100% {
    transform: translateY(-1000px) rotate(720deg);
    opacity: 0;
    // border-radius: 50%;
  }
}

.about-team-imgss {
  width: 350px;
  height: 350px;
  overflow: hidden;
  border-radius: 5px;
}

@media only screen and (max-width: 576px) {
  // .about-team-imgss {
    // height: 350px;
  // }
}

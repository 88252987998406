.gallery-imgs {
  background: #eff5ff;

  .gallery-img-1 {
    > div {
      &:nth-child(1) {
        overflow: hidden;
        width: 100%;
        height: 400px;
        border-radius: 15px;
        background: linear-gradient(180deg, #00b2ff -8.1%, #006dff 118.99%);
      }

      &:nth-child(2) {
        font-size: 20px;
        font-weight: 600;
      }
    }
  }

  .gallery-img-date {
    position: absolute;
    left: 0;
    top: 0;
    background: linear-gradient(180deg, #00b2ff -8.1%, #006dff 118.99%);
    font-size: 25px;
    font-weight: bold;
    color: #fff;
    border-bottom-right-radius: 20px;
  }

  .gallery-img-more {
    position: absolute;
    right: 0;
    bottom: 0;
    background: linear-gradient(180deg, #00b2ff -8.1%, #006dff 118.99%);
    font-size: 25px;
    font-weight: bold;
    color: #fff;
    border-top-left-radius: 20px;
  }
}

.navbar-main {
  // display: none;
  height: 100px;
  position: fixed;
  background: #fff;
  z-index: 999;
  transition: all 0.4s;
  width: 100%;

  &.active {
    position: fixed;
    width: 100%;
    top: 0;
    transition: all 0.4s;
    animation: navbarAnim 0.5s linear;
  }

  > div {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .navbar-logo {
      transition: all 0.3s;
      // transform: translateX(-20%);
      width: 250px;

      > .main-logo {
        transition: all 0.3s;
      }
    }

    .nav-menu {
      color: #000;

      > .nav-menu-items {
        padding: 10px 25px;
        margin-left: 12px;
        cursor: pointer;
        font-size: 16px;
        font-weight: 700 !important;
        transition: all 0.5s;
        border-radius: 50rem !important;
        // -webkit-text-fill-color: transparent;

        &:hover {
          background: #fff;
          color: #3677f7 !important;
        }

        &.active {
          background: #fff;
          color: #3677f7 !important;
        }
      }
    }
  }
}

@keyframes navbarAnim {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0%);
  }
}

.navbar-menu-trigger {
  cursor: pointer;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 30px;
  height: 30px;
  transition: all 0.5s;
  margin-right: 12px;
  transform: rotate(0deg);
  z-index: 9999 !important;

  > div:nth-child(1) {
    transition: all 0.5s;
    // position: absolute;
    // top: 0%;
    left: 0;
    width: 100%;
    height: 3px;
    border-radius: 20px;
    background: linear-gradient(to left, #38deff, #0053ff);
  }

  > div:nth-child(2) {
    transition: all 0.5s;
    // position: absolute;
    // bottom: 0%;
    left: 0;
    width: 100%;
    height: 3px;
    border-radius: 20px;
    background: linear-gradient(to left, #38deff, #0053ff);
  }

  > div:nth-child(3) {
    transition: all 0.5s;
    // position: absolute;
    // bottom: 0%;
    left: 0;
    width: 100%;
    height: 3px;
    border-radius: 20px;
    background: linear-gradient(to left, #38deff, #0053ff);
  }

  &.active {
    transform: rotate(90deg);

    > div:nth-child(1) {
      width: 60%;
    }

    > div:nth-child(2) {
      width: 80%;
    }

    // > div:nth-child(3) {}
    //   > div:nth-child(1) {
    //     // top: 40%;
    //   }

    //   > div:nth-child(2) {
    //     // bottom: 40%;
    //   }
  }
}

@keyframes navMenuTrigger {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(90deg);
  }
}

@media only screen and (max-width: 998px) {

  .navbar-main {
  
    &.active {
      animation: none;
    }
  }

  .main-logo-res {
    width: 70px;
    margin-left: 3px;
  }
}

.navbar-active-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.9);
  z-index: 99;
  color: #fff;
  transition: all 0.5s;
  transform: translateY(-100%);

  &.active {
    transform: translateY(0%);
  }

  > div {
    width: 100%;
    display: flex;
    justify-content: center;

    > .navbar-active-menu-item {
      width: 40%;

        > div {
          cursor: pointer;
          display: flex;
          align-items: center;
          margin: 20px 0;
          transition: all 0.5s;
          font-style: italic;
          letter-spacing: 0.5s;
          font-weight: 600;

          > div:nth-child(1) {
            width: 5px;
            height: 2px;
            background: linear-gradient(to left, #38deff, #387bff);
            margin-right: 12px;
            transition: all 0.5s;
          }

          > div:nth-child(2) {
            font-size: 23px;
          }

          &:hover {
            > div:nth-child(1) {
              width: 20px;
            }
          }


          &.active {
            > div:nth-child(1) {
              width: 20px;
            }
          }
        }
    }
  }
}

$note-width: 14px;
$note-margin: 3px;

$loader-padding: 32px;
$loader-height: 75px;

$notes: 5;

$anim-delay: 0.35s;
$anim-duration: 1s;

.loader-main {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loader {
  background: transparent;
  position: relative;
  height: $loader-height;
  width: (
    $notes * ($note-width + $note-margin) + ($loader-padding * 2) - $note-margin
  );
  overflow: hidden;
  box-shadow: -1px 12px 14px -15px #0000002b;

  &:after {
    content: "";
    width: 100%;
    height: $loader-height * 0.77;
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(
      180deg,
      rgb(255, 255, 255) 0%,
      rgba(255, 255, 255, 0) 100%
    );
  }
}

.status {
  color: #70757d;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  margin-top: 1rem;
}

@for $i from 0 to $notes {
  .note-#{$i} {
    position: absolute;
    background: linear-gradient(155.5deg, #00d5ff 15.65%, #0053ff 96.33%);
    width: $note-width;
    left: $i * ($note-width + $note-margin) + $loader-padding;
    height: $loader-height;
    top: 100%;
    animation: slide $anim-duration infinite $i * $anim-delay;
    animation-timing-function: ease-out;
    border-radius: 12px;
  }
}

@keyframes slide {
  0% {
    top: -100%;
  }
  100% {
    top: 100%;
  }
}
